import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Helmet } from 'react-helmet'
import PageBuilder from "../../components/PageBuilder"

const Page = props => {

  const pageData = props.data.page
  const bodyTag = props.path === 'home' ? 'home' : `${pageData.slug}`
  const hero = pageData.acfPageBuilder.components && pageData.acfPageBuilder.components.filter(component => component.__typename === 'WpPage_Acfpagebuilder_Components_Hero')
  const seoImg = hero !== null && hero.length  > 0 ? hero[0].heroImage ? hero[0].heroImage.localFile.url : undefined : undefined

  return (
    <Layout>
      <Seo
        title={pageData.title}
        seoTitle={pageData.seo.title}
        description={pageData.seo.metaDesc}
        seoImage={seoImg}
      />
      <Helmet bodyAttributes={{ class: `page ${bodyTag}` }} />
      <article>
        <PageBuilder {...props} />
      </article>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query Page($id: String!) {
    page: wpPage(id: {eq: $id}) {
      ...SinglePageFragment
    }
  }
`