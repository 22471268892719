import React, {useRef} from 'react'
import Slick from 'react-slick'
import { GatsbyImage } from "gatsby-plugin-image"

const ImageCarousel = props => {

  const data = props.data
  const images = data.images || []
  const sliderRef = useRef()

  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }

  const slideSettings = {
    arrows: false,
    infinite: true,
    fade: true,
    dots: false,
    cssEase: 'ease-in-out',
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 400,
      settings: {
        dots: false,
      }
    }],
  }

  return (
    <section className={`section ic mt10 wrapper`}>
      <div className={`headline`}>{data.headline}</div>
      <div className={`project-carousel`}>
          <Slick {...slideSettings} ref={sliderRef}>
          {images && images.map((image, i) => {

            return (
              <div className={`project`} key={i}>
                <div className={`project-details`}>
                  <div className={`title`}>{image.title}</div>
                </div>
                <div className={`project-image`}>
                    {image.image && <GatsbyImage
                      image={image.image.localFile.childImageSharp.gatsbyImageData}
                      key={i}
                      className={`lj-filter`}
                      alt={``} />}
                </div>
              </div>
            );
          })}
          </Slick>
          {images.length > 1 && <nav className={`nav`}>
          <button className={`next button`} onClick={gotoNext}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
              <path d="M41.45 10.64H22.51l18.94 19.73H9.09v14.2h32.36L22.51 64.3h18.94l24.46-26.83z" />
            </svg>
          </button>
          <button className={`prev button`} onClick={gotoPrev}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
              <path d="M41.45 10.64H22.51l18.94 19.73H9.09v14.2h32.36L22.51 64.3h18.94l24.46-26.83z" />
            </svg>
          </button>
        </nav>}
      </div>
    </section>
  )
}

export default ImageCarousel