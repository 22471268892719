import React from 'react'
import ContentComponent from './ContentComponent'
import Hero from './Hero'
import ImageContentComponent from './ImageContentComponent'
import ImgBkgTextComponent from './ImgBkgTextComponent'
import MultiColumnComponent from './MultiColumnComponent'
import StatComponent from './StatComponent'
import CircularImageRowComponent from './CircularImageRowComponent'
import IconsGridComponent from './IconsGridComponent'
import ProjectCarouselPages from './ProjectCarouselPages'
import ImageCarousel from './ImageCarousel'
import ProjectsColumnComponent from './ProjectsColumnComponent'
import ContactPageComponent from './ContactPage'

const PageBuilder = props => {

  const pageData = props.data.page

  return (
    <>
      {pageData.acfPageBuilder.components && (
        <>
          {pageData.acfPageBuilder.components.map((component, i) => {
            //if (component && component.fieldGroupName) {
              const type = component.fieldGroupName

              const componentProps = {
                data: component,
                pageData,
                props,
              }

              const Default = () => (<div><pre>In Page Builder, the mapping of this component is missing: <strong>{type}</strong></pre></div>)

              const componentList = {
                page_Acfpagebuilder_Components_Hero: Hero,
                page_Acfpagebuilder_Components_ContentSection: ContentComponent,
                page_Acfpagebuilder_Components_MultiColumn: MultiColumnComponent,
                page_Acfpagebuilder_Components_BackgroundImageWithText: ImgBkgTextComponent,
                page_Acfpagebuilder_Components_ImageWithContent: ImageContentComponent,
                page_Acfpagebuilder_Components_StatComponent: StatComponent,
                page_Acfpagebuilder_Components_CircularImageRow: CircularImageRowComponent,
                page_Acfpagebuilder_Components_IconsComponent: IconsGridComponent,
                page_Acfpagebuilder_Components_ProjectCarouselPages: ProjectCarouselPages,
                page_Acfpagebuilder_Components_ImageCarousel: ImageCarousel,
                page_Acfpagebuilder_Components_Projects: ProjectsColumnComponent,
                page_Acfpagebuilder_Components_ContactPage: ContactPageComponent,
                page_default: Default
              }

              const ComponentTag = componentList[type] ? componentList[type] : componentList["page_default"]

              return (
                <ComponentTag {...componentProps} key={i} />
              )
            //}
            
          })}
        </>
      )}
    </>
  )
}

export default PageBuilder