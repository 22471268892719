import React from 'react'
import parse from "html-react-parser"
import Svg from 'react-inlinesvg'

const ContactPageComponent = props => {

  const data = props.data
  const facilities = data.locations.facility
  const contacts = data.contacts.contact
  const socials = data.contacts.social

  return (
    <section className={`section wrapper cp`}>
      <div className={`flex col-3`}>
        <div className={`flex-item hero`}>
          {data.headline && <h1 className={`headline`}>{parse(data.headline)}</h1>}
        </div>

        <div className={`flex-item`}>
          <div className={`flex flex-column`}>
            {data.locations.headline && <div className={`headline`}>{data.locations.headline}</div>}
            {facilities && facilities.map((facility, i) => (
              <div className={`facility`} key={i}>
                {facility.title && <div className={`title`}><strong>{facility.title}</strong></div>}
                {facility.address && parse(facility.address)}
                {facility.phone && <div className={`phone`}>Phone: <a href={`tel:${facility.phone}`}>{facility.phone}</a></div>}
                {facility.fax && <div className={`fax`}>Fax: {facility.fax}</div>}
              </div>
            ))}
          </div>
        </div>

        <div className={`flex-item`}>
          <div className={`flex flex-column`}>
            {data.contacts.headline && <div className={`headline`}>{data.contacts.headline}</div>}
            {contacts && contacts.map((contact, i) => (
              <div className={`single-contact`} key={i}>
                {contact.title && <div className={`title`}><strong>{contact.title}</strong></div>}
                {contact.email && <div className={`email`}><a href={`mailto:${contact.email}`}>{contact.email}</a></div>}
              </div>
            ))}
            <div className={`social flex`}>
              {socials && socials.map((social, i) => (
                <div className={`svg-wrapper`} key={i}><a href={social.link} target="_blank" rel="noreferrer noopener" aria-label="Social Icon"><Svg className={`svg`} src={social.icon.localFile.publicURL} /></a></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactPageComponent