import React from 'react'
import {getImage} from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Bkg from 'gatsby-background-image'
import Button from './Button'
import parse from 'html-react-parser'

const ImgBkgTextComponent = props => {

  const data = props.data
  const img = getImage(data.backgroundImage.localFile)
  const bgImage = convertToBgImage(img)
  const hasBtn = data.sectionLink !== null ? 'has-link' : undefined

  return (
    <Bkg Tag={`section`} className={`section bic wrapper flex flex-column ${hasBtn}`} {...bgImage} preserveStackingContext>
      {data.sectionHeadline && <div className={`headline`}>{data.sectionHeadline}</div>}
      {data.sectionContent && <div className={`section-content`}>{parse(data.sectionContent)}</div>}
      {data.sectionLink && <div className={`section-link mt5`}><Button section={data.fieldGroupName} link={data.sectionLink} /></div>}
    </Bkg>
  )
}

export default ImgBkgTextComponent