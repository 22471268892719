import React from 'react'
import {useStandardStyles} from './Helpers'
import IconItem from './IconItem'
import Button from './Button'

const IconsGridComponent = props => {

  const data = props.data
  const getClassNames = useStandardStyles('igc')
  const cx = getClassNames(data)

  return (
    <section className={cx}>
      <div className={`wrapper flex flex-column`}>
        {data.headline && <header className={`mb5`}><div className={`headline`}>{data.headline}</div></header>}
        <div className={`grid col-${data.iconRepeater ? data.iconRepeater.length : null}`}>
          {data.iconRepeater 
            ? data.iconRepeater.map((item, i) => (
              <IconItem {...item} key={i} />
            ))
            : null
          }
        </div>
        {data.sectionLink && <Button space={`mt5`} link={data.sectionLink}/>}
      </div>
    </section>
  )
}

export default IconsGridComponent