import React from 'react'
import {useStandardStyles} from './Helpers'

const StatComponent = props => {

  const data = props.data
  const getClassNames = useStandardStyles('sc wrapper')
  const cx = getClassNames(data)

  return (
    <section className={cx}>
      <div className={`grid col-${data.stats ? data.stats.length : null}`}>
        {data.stats && data.stats.map((stat, i) => (
          <div key={i} className={`grid-item stat flex flex-column`}>
            <div className={`stat-count`}>{stat.statCount}</div>
            <div className={`description`}>{stat.statDescription}</div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default StatComponent