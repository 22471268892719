import React from 'react'
import Svg from 'react-inlinesvg'
import {Link} from 'gatsby'

const IconItem = item => {

  const Output = (
    <>
    <div className={`svg-wrapper`}>{item.icon && <Svg className={`svg`} src={item.icon.localFile.publicURL} />}</div>
    <div className={`title`}>{item.iconTitle}</div>
    </>
  )

  return (
    <>
    {item.iconLink
      ? <Link to={item.iconLink.url} className={`grid-item flex flex-column`}>{Output}</Link>
      : <div className={`grid-item flex flex-column`}>{Output}</div> 
    }
    </>
  )
}

export default IconItem