import React from 'react'
import Item from './CircularImageItem'

const CircularImageRowComponent = props => {

  const data = props.data

  return (
    <section className={`section cic wrapper`}>
      <div className={`grid col-${data.rowItems.length}`}>
        {data.rowItems && data.rowItems.map((item, i) => (
          <Item {...item} key={i} />
        ))}
      </div>
    </section>
  )
}

export default CircularImageRowComponent