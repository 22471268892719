import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import {Link} from 'gatsby'

const ProjectPreview = props => {

  const components = props.acfProjectBuilder.components
  const hero = components.filter(component => component.__typename === 'WpProject_Acfprojectbuilder_Components_Hero')

  return (
    <div className={`grid-item project pp`}>
      <Link to={props.uri}>
        {hero[0].heroImage && <GatsbyImage
          image={hero[0].heroImage.localFile.childImageSharp.gatsbyImageData}
          key={hero[0].heroImage.id}
          className={`lj-filter`}
          alt={``} />}
        <div className={`title`}><div className={`inner`}>{props.title}</div></div>
      </Link>
    </div>
  );
}
export default ProjectPreview