import React from 'react'
import {Link} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import {useStandardStyles} from './Helpers'
import Button from './Button'
import parse from "html-react-parser"

const ImageContentComponent = props => {

  const data = props.data
  const img = data.imageUpload && data.imageUpload.localFile.childImageSharp.gatsbyImageData
  const getClassNames = useStandardStyles('icc')
  const cx = getClassNames(data)

  const url = (data.link && data.link.url) || data.link
  const internal = /^\/(?!\/)/.test(url)
  
  return (
    <section className={cx}>
      <div className={`wrapper flex`}>
        {data.link 
          ? internal === true ? <Link className={`image-wrapper lj-filter`} to={url}>{img && <GatsbyImage image={img} alt={``} />}</Link> : <a href={data.link.url} className={`image-wrapper lj-filter`}>{img && <GatsbyImage image={img} alt={``} />}</a> 
          : <div className={`image-wrapper lj-filter`}>{img && <GatsbyImage image={img} alt={``} />}</div>
        }
        <div className={`content-wrapper`}>
          <div className={`headline`}>{data.headline}</div>
          {parse(data.content)}
          {data.link && <Button space={`mt2`} link={data.link} />}
        </div>
      </div>
    </section>
  );
}

export default ImageContentComponent