import React from 'react'
import Button from './Button'
import {shuffle} from './Helpers'

const ColumnContentFallback = props => {

  const title = props.columnTitle || props.title
  const link = props.columnLink || props.uri

  const v1 = (
    <>
    <div className={`shapes`}>
      <div className={`shape sq cut top rt`}></div>
      <div className={`shape sq sm white`}></div>
    </div>
    <div className={`title`}>{title}</div>
    <div className={`shapes bottom right`}>
      <div className={`shape rect lg cut`}></div>
    </div>
    </>
  )
  const v2 = (
    <>
    <div className={`shapes right`}>
      <div className={`shape sq cut top`}></div>
    </div>
    <div className={`title`}>{title}</div>
    <div className={`shapes bottom`}>
      <div className={`shape rect cut rt`}></div>
      <div className={`shape sq sm white`}></div>
    </div>
    </>
  )
  const v3 = (
    <>
    <div className={`shapes right`}>
      <div className={`shape rect sm`}></div>
      <div className={`shape sq sm white`}></div>
    </div>
    <div className={`title`}>{title}</div>
    <div className={`shapes bottom`}>
      <div className={`shape sq sm white`}></div>
    </div>
    </>
  )
  
  const variations = [v1,v2,v3]

  return (
    <div className={`grid-item gccni`}>
      <div className={`image-wrapper fallback`}>
        {shuffle(variations)}
      </div>
      <div className={`details`}>
        <div className={`content`}>
          {props.columnContent}
        </div>
        {link && <Button link={link} />}
      </div>
    </div>
  )

}

export default ColumnContentFallback