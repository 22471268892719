import React, {useState} from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const CircularImageItem = props => {

  const [setActive, setActiveState] = useState("")
  const toggleActive = () => {
    setActiveState(setActive === "" ? "active" : "")
  }
  const img = props.image && props.image.localFile.childImageSharp.gatsbyImageData

  return (
    <div className={`grid-item flex flex-column`}>
      <div className={`image-wrapper lj-filter`}>{img && <GatsbyImage image={img} alt={``} />}</div>
      {props.headline && <div className={`headline`}>{props.headline}</div>}
      {props.subheadline && <div className={`subheadline`}>{props.subheadline}</div>}
      {props.itemContent && <div className={`button mt2 ${setActive}`} role="button" tabIndex={0} aria-label="More Details" onClick={toggleActive} onKeyDown={toggleActive}><div className={`plus`}></div></div>}
      {props.itemContent && <div className={`content ${setActive} mt2`}>{props.itemContent}</div>}
    </div>
  );
}

export default CircularImageItem