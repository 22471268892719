import React, {useRef} from 'react'
import Slick from 'react-slick'
import {Link} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectCarouselPages = props => {

  const data = props.data
  const projects = data.featuredProjects

  const sliderRef = useRef()

  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }

  const slideSettings = {
    arrows: false,
    infinite: true,
    fade: true,
    dots: true,
    cssEase: 'ease-in-out',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 400,
      settings: {
        dots: false,
      }
    }],
  }

  return (
    <section className={`section pcp wrapper`}>
      <div className={`headline`}>{data.headline}</div>
      <div className={`project-carousel`}>
        <Slick {...slideSettings} ref={sliderRef}>
        {projects && projects.map((project, i) => {
          const components = project.acfProjectBuilder.components
          const hero = components.filter(component => component.__typename === 'WpProject_Acfprojectbuilder_Components_Hero')
          const images = getImage(hero[0].heroImage.localFile.full)

          return (
            <div className={`project`} key={i}>
              <div className={`project-details`}>
                <div className={`title`}>{project.title}</div>
                <div className={`project-link`}><Link to={project.uri}>View Project</Link></div>
              </div>
              {hero && <div className={`project-image`}>
                  {hero[0].heroImage !== null && <GatsbyImage image={images} key={hero[0].heroImage.id} className={`lj-filter`} alt={``} />}
              </div>}
            </div>
          );
        })}
        </Slick>
        {projects.length > 1 && <nav className={`nav`}>
          <button className={`next button`} onClick={gotoNext}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
              <path d="M41.45 10.64H22.51l18.94 19.73H9.09v14.2h32.36L22.51 64.3h18.94l24.46-26.83z" />
            </svg>
          </button>
          <button className={`prev button`} onClick={gotoPrev}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
              <path d="M41.45 10.64H22.51l18.94 19.73H9.09v14.2h32.36L22.51 64.3h18.94l24.46-26.83z" />
            </svg>
          </button>
        </nav>}
      </div>
    </section>
  );
}

export default ProjectCarouselPages