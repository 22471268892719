import React from 'react'
import ColumnContent from './ColumnContent'
import Button from './Button'
import {useStandardStyles} from './Helpers'

const MultiColumnComponent = props => {

  const data = props.data
  const getClassNames = useStandardStyles('mc')
  const cx = getClassNames(data)

  return (
    <section className={cx}>
      <div className={`wrapper flex flex-column`}>
        {data.sectionHeadline && <div className={`headline`}>{data.sectionHeadline}</div>}
        {data.columns && <div className={`grid col-${data.columns.length}`}>
          {data.columns.map((column, i) => (
            <ColumnContent {...column} key={i} />
          ))}
        </div>}
        {data.sectionLink && <Button space={`mt5`} link={data.sectionLink} />}
      </div>
    </section>
  )
}

export default MultiColumnComponent