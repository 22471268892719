import React from 'react'
import Fallback from './ColumnContentFallback'
import ProjectPreview from './ProjectPreview'

const ProjectsColumnComponent = props => {

  const data = props.data

  return (
    <section className={`section pcc wrapper`}>
      {data.title && <header className={`section-title`}>{data.title}</header>}
      <div className={`grid col-3`}>
        {data.projects && data.projects.map((project, i) => {
          const components = project.acfProjectBuilder.components
          const hero = components.filter(component => component.__typename === 'WpProject_Acfprojectbuilder_Components_Hero')

          if (hero === null || hero.length === 0) {
            return <Fallback {...project} key={i}/>
          }
          return <ProjectPreview {...project} key={i} />
        })}
      </div>
    </section>
  )
}

export default ProjectsColumnComponent